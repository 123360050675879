/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@angular/material/theming';
@import 'app/app-theme';
@import 'app/assets/highlightjs/material-light.css';
@import '../node_modules/ngx-toasta/styles/style-material.css';

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@include mat-core();

$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-pink, A200, A100, A400);

$theme: mat-light-theme($primary, $accent);
@include angular-material-theme($theme);
@include quickapp-pro-app-theme($theme);

body {
  font-family: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  margin: 0;
}

.page-content {
  padding: 0 2em;
}

.mat-card-content,
.mat-card-header .mat-card-title,
.mat-expansion-panel-content {
  font-size: 16px;
}

.mat-panel-title {
  display: inline-block;
  vertical-align: middle;
}

.mat-form-field.app-validate.ng-valid {

  $valid-color: #42A948;

  .mat-form-field-label {
    color: $valid-color;
  }

  .mat-form-field-underline {
    background-color: $valid-color;
  }
}

.mat-dialog-sm {
  min-width: 40vw;

  @media screen and (max-width: 959px) {
    min-width: 90vw;
  }
}

.mat-dialog-md {
  min-width: 50vw;

  @media screen and (max-width: 959px) {
    min-width: 90vw;
  }
}

.mat-dialog-lg {
  min-width: 80vw;

  @media screen and (max-width: 959px) {
    min-width: 90vw;
  }
}

.mat-button-icon {
  margin-right: 16px;
}

.mat-icon.mat-icon-lg {
  width: 48px;
  height: 48px;
  font-size: 48px;
}

.vertical-center {
  display: inline-block;
  vertical-align: middle;
}

#toasta.toasta-position-top-right {
  top: 80px;
  right: 20px;
}

#toasta .toast-text .toast-msg {
  max-width: 1000px;
  max-height: 800px;
  display: inline-block;
  overflow: auto;
}

.mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font: {
      family: var(--elixir-body-font-family);
      size: var(--elixir-body-font-size);
      weight: var(--elixir-body-font-weight);
    }
}

.mat-expansion-panel-body {
  padding: 0.3em !important;
}

.spinner-button {
  svg {
    display: inline-flex;
  }
  circle {
    stroke: currentColor;
  }
}

.googleIcon {
  svg {
    vertical-align: baseline !important;
  }
}

.appleIcon {
  svg {
    vertical-align: baseline !important;
  }
}

.facebookIcon {
  svg {
    vertical-align: baseline !important;
  }
}
